import { default as indexLrwcLXziJkMeta } from "/usr/src/app/pages/hoerbuch/index.vue?macro=true";
import { default as index4ZKQYFVNDYMeta } from "/usr/src/app/pages/impressum/index.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as indexfBz8Uu0vdeMeta } from "/usr/src/app/pages/podcast/index.vue?macro=true";
import { default as indexFzL823LV67Meta } from "/usr/src/app/pages/story/index.vue?macro=true";
export default [
  {
    name: indexLrwcLXziJkMeta?.name ?? "hoerbuch",
    path: indexLrwcLXziJkMeta?.path ?? "/hoerbuch",
    meta: indexLrwcLXziJkMeta || {},
    alias: indexLrwcLXziJkMeta?.alias || [],
    redirect: indexLrwcLXziJkMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/hoerbuch/index.vue").then(m => m.default || m)
  },
  {
    name: index4ZKQYFVNDYMeta?.name ?? "impressum",
    path: index4ZKQYFVNDYMeta?.path ?? "/impressum",
    meta: index4ZKQYFVNDYMeta || {},
    alias: index4ZKQYFVNDYMeta?.alias || [],
    redirect: index4ZKQYFVNDYMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/impressum/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfBz8Uu0vdeMeta?.name ?? "podcast",
    path: indexfBz8Uu0vdeMeta?.path ?? "/podcast",
    meta: indexfBz8Uu0vdeMeta || {},
    alias: indexfBz8Uu0vdeMeta?.alias || [],
    redirect: indexfBz8Uu0vdeMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/podcast/index.vue").then(m => m.default || m)
  },
  {
    name: indexFzL823LV67Meta?.name ?? "story",
    path: indexFzL823LV67Meta?.path ?? "/story",
    meta: indexFzL823LV67Meta || {},
    alias: indexFzL823LV67Meta?.alias || [],
    redirect: indexFzL823LV67Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/story/index.vue").then(m => m.default || m)
  }
]