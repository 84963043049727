import { defineStore } from "pinia";

export const useViewStore = defineStore("ViewStore", {
  state: () => ({
    selfActive: true,
    isMobile: false,
  }),
  actions: {
    toggleSelfActive(input: boolean) {
      return (this.selfActive = input);
    },
    toggleIsMobile(input: boolean) {
      return (this.isMobile = input);
    },
  },
  getters: {
    selfOrCompany() {
      if (this.selfActive == true) {
        return "self";
      } else {
        return "company";
      }
    },
  },
});
